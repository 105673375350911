import * as i0 from '@angular/core';
import { Pipe, inject, InjectionToken, input, computed, Component, ChangeDetectionStrategy, NgModule, makeEnvironmentProviders } from '@angular/core';
import { NgOptimizedImage, NgClass, IMAGE_LOADER } from '@angular/common';
function ImageComponent_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("object-fit", ctx_r0.fill());
    i0.ɵɵproperty("ngSrc", ctx_r0.safeFileName$())("width", ctx_r0.width())("height", ctx_r0.height())("alt", ctx_r0.alt() || ctx_r0.image().alt)("ngSrcset", ctx_r0.ngSrcset())("sizes", ctx_r0.colSizes() || ctx_r0.sizes())("loaderParams", ctx_r0.allLoaderParams())("priority", ctx_r0.priority())("ngClass", (ctx_r0.fill() ? "object-position--" + ctx_r0.image().anchor : "") + " " + ctx_r0.cssClass());
    i0.ɵɵattribute("loading", ctx_r0.priority() ? null : ctx_r0.loading());
  }
}
function ImageComponent_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("object-fit", ctx_r0.fill());
    i0.ɵɵproperty("ngSrc", ctx_r0.safeFileName$())("width", ctx_r0.width())("alt", ctx_r0.alt() || ctx_r0.image().alt)("ngSrcset", ctx_r0.ngSrcset())("sizes", ctx_r0.colSizes() || ctx_r0.sizes())("loaderParams", ctx_r0.allLoaderParams())("priority", ctx_r0.priority())("fill", ctx_r0.fill())("ngClass", (ctx_r0.fill() ? "object-position--" + ctx_r0.image().anchor : "") + " " + ctx_r0.cssClass());
    i0.ɵɵattribute("loading", ctx_r0.priority() ? null : ctx_r0.loading());
  }
}
function ImageComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ImageComponent_Conditional_0_Conditional_0_Template, 1, 12, "img", 1)(1, ImageComponent_Conditional_0_Conditional_1_Template, 1, 12, "img", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(ctx_r0.image().url && !ctx_r0.fill() ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r0.image().url && ctx_r0.fill() ? 1 : -1);
  }
}
function ImageComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("--placeholder-image", ctx_r0.placeholderUrl)("--placeholder-aspect-ratio", ctx_r0.width() > 0 && ctx_r0.height() > 0 ? ctx_r0.width() + "/" + ctx_r0.height() : "auto");
  }
}
class ImageProcessorConfig {
  parseAnchor(anchor) {
    return anchor;
  }
}
var ImageProcessor;
(function (ImageProcessor) {
  ImageProcessor[ImageProcessor["ImageFlow"] = 0] = "ImageFlow";
  ImageProcessor[ImageProcessor["ImageSharp"] = 1] = "ImageSharp";
})(ImageProcessor || (ImageProcessor = {}));
const ImageFlowConfig = {
  options: {
    width: 'width',
    height: 'height',
    anchor: 'anchor',
    mode: 'mode',
    format: 'format',
    quality: 'quality',
    scale: 'scale',
    other: 'other'
  },
  parseAnchor: anchor => {
    return anchor;
  }
};
const ImageSharpConfig = {
  options: {
    width: 'width',
    height: 'height',
    anchor: 'ranchor',
    mode: 'rmode',
    format: 'format',
    quality: 'quality',
    scale: 'scale',
    other: 'other'
  },
  parseAnchor: anchor => {
    switch (anchor) {
      case 'topleft':
        return anchor;
      case 'topcenter':
        return 'top';
      case 'topright':
        return anchor;
      case 'middleleft':
        return 'left';
      case 'middlecenter':
        return 'center';
      case 'middleright':
        return 'right';
      case 'bottomleft':
        return anchor;
      case 'bottomcenter':
        return 'bottom';
      case 'bottomright':
        return anchor;
      default:
        return anchor;
    }
  }
};
const defaultConfig = ImageFlowConfig;
class ResizePipe {
  constructor(config) {
    this.config = config;
  }
  transform(imageUrl, width, height, anchor, mode, format, scale, other) {
    if (!imageUrl) {
      return imageUrl;
    }
    let val = `${imageUrl}`;
    if (width) {
      val = `${val}${this.separator(val)}${this.config.options.width}=${width}`;
    }
    if (height) {
      val = `${val}${this.separator(val)}${this.config.options.height}=${height}`;
    }
    if (anchor) {
      val = `${val}${this.separator(val)}${this.config.options.anchor}=${this.config.parseAnchor(anchor)}`;
    }
    if (mode) {
      val = `${val}${this.separator(val)}${this.config.options.mode}=${mode}`;
    }
    if (format) {
      val = `${val}${this.separator(val)}${this.config.options.format}=${format}`;
    }
    if (scale) {
      val = `${val}${this.separator(val)}${this.config.options.scale}=${scale}`;
    }
    if (other) {
      val = `${val}${this.separator(val)}${other}`;
    }
    return val;
  }
  separator(url) {
    return url.indexOf('?') >= 0 ? '&' : '?';
  }
  static {
    this.ɵfac = function ResizePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ResizePipe)(i0.ɵɵdirectiveInject(ImageProcessorConfig, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "resize",
      type: ResizePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizePipe, [{
    type: Pipe,
    args: [{
      name: 'resize',
      standalone: true
    }]
  }], () => [{
    type: ImageProcessorConfig
  }], null);
})();
class ResizeHtmlImagesPipe {
  constructor() {
    // Inherited from CSS: $grid-breakpoints
    // is used to generate size attribute with collspans
    this.cssBreakPoints = {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    };
    this.config = inject(ImageProcessorConfig);
    this.resize = new ResizePipe(this.config);
  }
  transform(htmlContent, width = 1000, format = 'webp', imageSizes = [600, 800, 900, 1200]) {
    if (!htmlContent) {
      return htmlContent;
    }
    const queryParams = `?width=${width}&format=${format}&quality=80&scale=both`;
    // htmlContent = htmlContent.replace(
    //   /(<img src=")([^"]+)"/g,
    //   (match, p1, p2) => `${p1}${p2}${queryParams}"`
    // );
    htmlContent = htmlContent.replace(/(<img src=")([^"]+)"/g, (match, p1, p2) => {
      // console.log(p2);
      const imgParams = this.resize.transform(p2, width, undefined, undefined, undefined, format, 'both', undefined);
      const srcSet = this.generateSrcSet(p2, imageSizes, format);
      return `${p1}${imgParams}" srcset="${srcSet}"`;
    });
    return htmlContent;
  }
  // private generateSrcSet(baseUrl: string, sizes: number[], format: string): string {
  //   return sizes.map(size => `${baseUrl}?width=${size}&format=${format}&quality=80&scale=both ${size}w`).join(', ');
  // }
  generateSrcSet(baseUrl, sizes, format) {
    return sizes.map(size => {
      // Gebruik `this.resize.transform` voor elke URL
      const resizedUrl = this.resize.transform(baseUrl, size, undefined, undefined, undefined, format, 'both', undefined);
      return `${resizedUrl} ${size}w`;
    }).join(', ');
  }
  static {
    this.ɵfac = function ResizeHtmlImagesPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ResizeHtmlImagesPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "resizeHtmlImages",
      type: ResizeHtmlImagesPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResizeHtmlImagesPipe, [{
    type: Pipe,
    args: [{
      name: 'resizeHtmlImages',
      standalone: true
    }]
  }], () => [], null);
})();

/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionTToken used to import the config object, provided from the outside
 */
const ImageConfigService = new InjectionToken('ImageConfig');
class ImageComponent {
  constructor() {
    //private config = inject(ImageProcessorConfig);
    this.config = inject(ImageConfigService);
    // https://angular.io/api/common/NgOptimizedImage#properties
    this.loading = input('auto');
    this.priority = input(false);
    this.loaderParams = input({});
    this.disableOptimizedSrcset = input(false);
    this.fill = input(false);
    this.showPlaceholderFallback = input(true);
    this.image = input();
    this.alt = input();
    this.ngSrcset = input('550w, 800w, 1200w, 1600w, 1920w'); // beschikbare afbeelding formaten
    this.sizes = input('100vw');
    // When both width and height are filled, this is used to calculate the aspect-ratio
    this.width = input(0);
    this.height = input(0);
    this.cssClass = input('');
    // Image processing options
    this.mode = input();
    this.format = input(this.config?.defaultFormat || 'webp');
    this.quality = input(80);
    this.scale = input();
    this.other = input(); // add other nonstandard query params like trim.threshold=90
    this.anchor = input(); // overrides the default anchor in image.anchor
    // Inherited from CSS: $grid-breakpoints
    // is used to generate size attribute with collspans
    this.cssBreakPoints = {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    };
    this.colSpan = input();
    this.allLoaderParams = computed(() => {
      const loaderParams = this.loaderParams();
      return {
        width: this.width(),
        height: this.height(),
        anchor: this.anchor() || this.image()?.anchor,
        mode: this.mode(),
        format: this.format(),
        quality: this.quality(),
        scale: this.scale(),
        other: this.other(),
        ...loaderParams
      };
    });
    this.safeFileName$ = computed(() => {
      const image = this.image();
      if (image && image.url) {
        return image.url.replace(/ /g, '%20');
      }
      return;
    });
    // TEST bootstrap columns
    this.colSizes = computed(() => {
      const colSpan = this.colSpan();
      if (!colSpan || !Object.keys(colSpan).length) {
        return;
      }
      const sizeRules = [];
      // Sets for each container width an srcset (so for full width images)
      Object.keys(colSpan).forEach(key => {
        const size = colSpan[key];
        const breakpoint = this.cssBreakPoints[key];
        if (!size || !breakpoint) {
          return;
        }
        const width = 100 / 12 * size;
        sizeRules.push(`(min-width: ${breakpoint}px) ${width}vw`); // TODO calculate width with max width of container & vw?
      });
      return sizeRules.join(',').concat(', 100vw');
    });
    if (!this.config?.placeholderUrl) {
      console.error('No placeholderUrl URL defined');
    }
  }
  get placeholderUrl() {
    return `url('${this.config?.placeholderUrl}')`;
  }
  static {
    this.ɵfac = function ImageComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ImageComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ImageComponent,
      selectors: [["fstr-image"]],
      inputs: {
        loading: [1, "loading"],
        priority: [1, "priority"],
        loaderParams: [1, "loaderParams"],
        disableOptimizedSrcset: [1, "disableOptimizedSrcset"],
        fill: [1, "fill"],
        showPlaceholderFallback: [1, "showPlaceholderFallback"],
        image: [1, "image"],
        alt: [1, "alt"],
        ngSrcset: [1, "ngSrcset"],
        sizes: [1, "sizes"],
        width: [1, "width"],
        height: [1, "height"],
        cssClass: [1, "cssClass"],
        mode: [1, "mode"],
        format: [1, "format"],
        quality: [1, "quality"],
        scale: [1, "scale"],
        other: [1, "other"],
        anchor: [1, "anchor"],
        colSpan: [1, "colSpan"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 2,
      consts: [[1, "placeholder-image", 3, "--placeholder-image", "--placeholder-aspect-ratio"], [3, "ngSrc", "width", "height", "alt", "ngSrcset", "sizes", "loaderParams", "priority", "ngClass", "object-fit"], [1, "object-fit", 2, "position", "relative", 3, "ngSrc", "width", "alt", "ngSrcset", "sizes", "loaderParams", "priority", "fill", "ngClass", "object-fit"], [3, "ngSrc", "width", "height", "alt", "ngSrcset", "sizes", "loaderParams", "priority", "ngClass"], [1, "object-fit", 2, "position", "relative", 3, "ngSrc", "width", "alt", "ngSrcset", "sizes", "loaderParams", "priority", "fill", "ngClass"], [1, "placeholder-image"]],
      template: function ImageComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, ImageComponent_Conditional_0_Template, 2, 2)(1, ImageComponent_Conditional_1_Template, 1, 4, "div", 0);
        }
        if (rf & 2) {
          let tmp_0_0;
          let tmp_1_0;
          i0.ɵɵconditional(ctx.image() && ((tmp_0_0 = ctx.image()) == null ? null : tmp_0_0.url) ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.showPlaceholderFallback() && !((tmp_1_0 = ctx.image()) == null ? null : tmp_1_0.url) ? 1 : -1);
        }
      },
      dependencies: [NgOptimizedImage, NgClass],
      styles: [".object-fit[_ngcontent-%COMP%]{object-fit:cover}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageComponent, [{
    type: Component,
    args: [{
      selector: 'fstr-image',
      standalone: true,
      imports: [NgOptimizedImage, NgClass],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "\n\n@if (image() && image()?.url) { @if (image()!.url && !fill()) {\n<img\n  [ngSrc]=\"safeFileName$()!\"\n  [width]=\"width()\"\n  [height]=\"height()\"\n  [alt]=\"alt() || image()!.alt\"\n  [ngSrcset]=\"ngSrcset()\"\n  [sizes]=\"colSizes() || sizes()\"\n  [loaderParams]=\"allLoaderParams()\"\n  [priority]=\"priority()\"\n  [attr.loading]=\"priority() ? null : loading()\"\n  [ngClass]=\"(fill() ? 'object-position--' + image()!.anchor : '') + ' ' + cssClass()\"\n  [class.object-fit]=\"fill()\"\n/>\n} @if (image()!.url && fill()) {\n<img\n  [ngSrc]=\"safeFileName$()!\"\n  [width]=\"width()\"\n  [alt]=\"alt() || image()!.alt\"\n  [ngSrcset]=\"ngSrcset()\"\n  [sizes]=\"colSizes() || sizes()\"\n  [loaderParams]=\"allLoaderParams()\"\n  [priority]=\"priority()\"\n  [fill]=\"fill()\"\n  [attr.loading]=\"priority() ? null : loading()\"\n  [ngClass]=\"(fill() ? 'object-position--' + image()!.anchor : '') + ' ' + cssClass()\"\n  [class.object-fit]=\"fill()\"\n  style=\"position: relative\"\n  class=\"object-fit\"\n/>\n} } @if(showPlaceholderFallback() && !image()?.url) {\n<div\n  class=\"placeholder-image\"\n  [style.--placeholder-image]=\"placeholderUrl\"\n  [style.--placeholder-aspect-ratio]=\"width() > 0 && height() > 0 ? width() + '/' + height() : 'auto'\"\n></div>\n}\n\n",
      styles: [".object-fit{object-fit:cover}\n"]
    }]
  }], () => [], null);
})();
class ImageModule {
  static forRoot(config) {
    return {
      ngModule: ImageModule,
      providers: [{
        provide: ImageConfigService,
        useValue: {
          ...defaultConfig,
          ...config
        }
      }, {
        provide: ImageProcessorConfig,
        useValue: {
          ...defaultConfig,
          ...config
        }
      }]
    };
  }
  static {
    this.ɵfac = function ImageModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ImageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ImageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageModule, [{
    type: NgModule,
    args: [{
      exports: [ResizePipe, ResizeHtmlImagesPipe, ImageComponent],
      imports: [ResizePipe, ResizeHtmlImagesPipe, ImageComponent]
    }]
  }], null, null);
})();

//aspect ratio
//https://github.com/angular/angular/pull/51951
function seperator(url) {
  return url.indexOf('?') >= 0 ? '&' : '?';
}
function aspectRatio(width, height) {
  if (!height || !width) {
    return 0;
  }
  return height / width;
}
function transform(config, processorConfig) {
  if (!config.src) {
    return config.src;
  }
  let val = `${config.src}`;
  const originalHeight = config.loaderParams?.['height'];
  const originalWidth = config.loaderParams?.['width'];
  // console.log('[INPUT] Image transform ', config, processorConfig);
  if (!config.width) {
    if (originalHeight) {
      val = `${val}${seperator(val)}${processorConfig.options.height}=${originalHeight}`;
    }
    if (originalWidth) {
      val = `${val}${seperator(val)}${processorConfig.options.width}=${originalWidth}`;
    }
  } else {
    // Width changes with every ngSrcset size, so directly apply it
    val = `${val}${seperator(val)}${processorConfig.options.width}=${config.width}`;
  }
  // Array of options for our image processor
  const availableStandardOptions = Object.keys(processorConfig.options);
  availableStandardOptions.splice(availableStandardOptions.indexOf('width'), 1); // already applied width from config/srcset
  const ratio = aspectRatio(originalWidth, originalHeight);
  availableStandardOptions.forEach(option => {
    if (!config.loaderParams?.[option] || Object.keys(processorConfig.options).indexOf(option) === -1) {
      return;
    }
    // Custom calculate height based on aspect ratio and new width
    if (option === 'height' && config.width) {
      val = `${val}${seperator(val)}${option}=${Math.round(ratio * config.width)}`;
      return;
    }
    if (option === 'other') {
      val = `${val}${seperator(val)}${config.loaderParams?.[option]}`;
      return;
    }
    val = `${val}${seperator(val)}${processorConfig.options[option]}=${config.loaderParams?.[option]}`;
  });
  // console.log('[OUTPUT] Image transform', val);
  return val;
}
//https://docs.imageflow.io/querystring/introduction.html

function ProvideOptimisedImageProcessor(config, additionalConfig) {
  // using default values for missing properties
  const merged = {
    ...defaultConfig,
    ...config
  };
  const merged2 = {
    placeholderUrl: 'https://www.teamfoster.nl/media/uploads/Placeholder_view_vector.svg',
    defaultFormat: 'webp',
    ...additionalConfig
  };
  return makeEnvironmentProviders([{
    provide: IMAGE_LOADER,
    useValue: (config, b) => {
      return transform(config, merged);
    }
  }, {
    provide: ImageProcessorConfig,
    useValue: merged
  }, {
    provide: ImageConfigService,
    useValue: merged2
  }]);
}
function ProvideImageProcessor(config, additionalConfig) {
  // using default values for missing properties
  const merged = {
    ...defaultConfig,
    ...config
  };
  return makeEnvironmentProviders([{
    provide: ImageProcessorConfig,
    useValue: merged
  }, ProvideOptimisedImageProcessor({}, {})]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { ImageComponent, ImageFlowConfig, ImageModule, ImageProcessor, ImageProcessorConfig, ImageSharpConfig, ProvideImageProcessor, ProvideOptimisedImageProcessor, ResizeHtmlImagesPipe, ResizePipe, defaultConfig };
